@import '../../../styles/basics';

.page-preview {
  height: 64px;
  background: $slate-1200;
  color: $white;
  line-height: 64px;

  :global {
    .preview-banner {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      height: 64px;

      .viewport-buttons {
        display: flex;

        .viewport-button {
          height: 100%;
          cursor: pointer;
          padding: 0 16px;
          box-shadow: 0 1px 1px $white;

          &:hover {
            background: $slate-1000;
          }

          &.active {
            background: $slate-1000;
          }

          svg {
            width: 32px;
            height: 32px;
            fill: $white;
          }
        }
      }

      .actionbar {
        .button-close {
          cursor: pointer;
          width: 32px;
          height: 32px;

          circle {
            transition: 0.3s all;
          }

          &:hover {
            circle {
              fill: $turquoise-1200;
            }
          }
        }
      }
    }

    .preview-iframe {
      margin: 0 auto;
      display: block;
      border: none;
      box-shadow: 0 1px 1px $black;

      &.viewport-desktop {
        width: 100%;
        height: calc(100vh - 64px);
      }

      &.viewport-tablet {
        width: 768px;
        height: calc(100vh - 64px);
      }

      &.viewport-mobile {
        width: 375px;
        height: calc(100vh - 64px);
      }
    }
  }
}
